import Image from 'next/image'
import React from 'react'

export default function BannerImgs({item}:any) {
  return (
    <Image property={'high'} quality={80} width={720} height={320} 
    //src={`/images/${item}`}
   // src={`https://beta-next.invenzolabs.com/images/${item}`}
    src={`https://invenzolabs.com/images/${item}`}
    sizes="(max-width: 420px) 100vw, (max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
    alt='Incenzo Labs' className='xs:h-auto xs:w-full m-auto xs:min-h-[280px]' loading='eager' />
  )
}
   